
.login{
  display: grid;
  height: 100vh;
  width: 100vw;

  .login-container{
    margin: auto;
  }

  .buttons{
    button{
      margin-right: 20px;
    }
  }
}

.chat{
  height: 100vh;
  width: 100vw;

  .chat-thread{
    height: 100%;
    width: 100%;
    padding:  20px;
    margin-bottom: 30vh;


    .chat-section{
      width: 100%;
      display: flex;
      padding: 10px;
      border-bottom: 1px solid #ddd;

      .avatar{
        $d: 50px;
        height: $d;
        width: $d;
        object-fit: cover;
        margin-right: 10px;
        border-radius: $d;
        border: 2px solid rgb(201, 60, 84);
      }


    }
  }


  input{
    width: 100%;
    position: fixed;
    bottom: 0px;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 0px;
    outline: none;
    transition: 0.3s;

    &:focus{
    transition: 0.3s;
    border: 2px solid rgb(201, 60, 84);
    }
  }
}